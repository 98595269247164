const categories: Category[] = [
  {
    name: 'wijn',
    inNav: true,
    displayName: 'wijn',
    as: '/wijn/',
    href: '/[categoryName]',
    // subCategories: null,
    facets: ['color', 'country', 'region', 'appellation'],
    subCategories: [
      { name: 'Rood', as: '/wijn/?color=red', href: '/[categoryName]?color=red' },
      { name: 'Rosé', as: '/wijn/?color=rose', href: '/[categoryName]/?color=rose' },
      { name: 'Wit', as: '/wijn/?color=white', href: '/[categoryName]/?color=white' },
    ],
    description:
      'Bij Shopdeal kan u een groot aanbod van wijnen vinden die aantrekkelijk zijn zowel in prijs als in smaak. Shopdeal.be is een echte prijskraker op alle wijnen. Bezoek regelmatig onze website voor de laatste deals.',
  },
  {
    name: 'bier',
    inNav: true,
    displayName: 'bier',
    as: '/bier/',
    href: '/[categoryName]',
    subCategories: null,
    facets: [],
    description:
      'Shopdeal voorziet u in een groot aanbod van verschillende bieren zoals Mikkeller Weird Weather, 888 Tripel Eight, Besos Tequila, Boerinneken blond, Corona en vele andere bieren. Allemaal aan sterk verminderde prijzen. Echte deals om van te drinken.',
  },
  {
    name: 'champagne',
    inNav: true,
    displayName: 'champagne',
    href: '/[categoryName]',
    as: '/champagne/',
    subCategories: null,
    facets: ['color', 'country', 'region', 'grape'],
    description:
      'Bij Shopdeal.be kan u de scherpste prijzen vinden voor Champangnes voor merken zoals Armand De Brignac, Autréau - Blanc de blancs Grand Cru, Autréau - Perles de la Dhuy, Bollinger - Special Cuvée giftbox, Comte Remy de Vallicourt, Moët & Chandon en vele andere kwaliteitschampagnes.',
  },
  {
    name: 'gin',
    inNav: true,
    displayName: 'gin',
    href: '/[categoryName]',
    as: '/gin/',
    subCategories: null,
    facets: ['country'],
    description:
      'Maak uw keuze tussen ons uitgebreid aanbod van gins. 7 Sins Pride, Alidor, Bankes London Dry, Black Gin Gansloser, Blind Tiger Piper Cubeba, Bombay Bramble en vele andere. Wij staan garant voor een scherpe prijs.',
  },
  {
    name: 'vodka',
    inNav: true,
    displayName: 'vodka',
    href: '/[categoryName]',
    as: '/vodka/',
    subCategories: null,
    facets: ['country'],
    description:
      'Geniet van onze kwaliteitsvolle Wodka’s . Zonder meer één voor één waard om in ons gamma van Vodka’s te staan. Allemaal aan scherpe prijzen en geweldige deals. Bezoek regelmatig onze website voor de laatste deals voor vodka merken zoals Absolut, Beluga, Belvedere, Ciroc, Eristoff en vele andere vodka’s met hoge kwaliteit.',
  },
  {
    name: 'rum',
    inNav: true,
    displayName: 'rum',
    href: '/[categoryName]',
    as: '/rum/',
    subCategories: null,
    facets: ['country'],
    description:
      'Shopdeal heeft voor de liefhebber van rum (Rhum) een uitgebreid aanbod voorzien dat er telkens in slaagt om zowel de smaak als de prijs positief te benaderen. Ben je een liefhebben van de witte of buine rum, bezoek dan nu Shopdeal.be.',
  },
  {
    name: 'whisky',
    inNav: true,
    displayName: 'whisky',
    href: '/[categoryName]',
    as: '/whisky/',
    subCategories: null,
    facets: ['country'],
    description:
      'Shopdeal biedt u een uitgebreid aanbod van verschillende whiskey’s aan. Ben je een bourbon genieter of eerder een rye whisky fan, Shopdeal voorziet de scherpste prijzen voor alle soorten whisky. Bezoek ons snel om meer hierover te vinden.',
  },
  {
    name: 'spirits',
    inNav: true,
    displayName: 'spirits',
    href: '/[categoryName]',
    as: '/spirits/',
    subCategories: [
      { name: 'Jenever', as: '/spirits/?type=jenever', href: '/[categoryName]/?type=jenever' },
      { name: 'Tequila', as: '/spirits/?type=tequila', href: '/[categoryName]/?type=tequila' },
      { name: 'Likeuren', as: '/spirits/?type=likeuren', href: '/[categoryName]/?type=likeuren' },
      { name: 'Porto', as: '/spirits/?type=porto', href: '/[categoryName]/?type=porto' },
      { name: 'Cognac', as: '/spirits/?type=cognac', href: '/[categoryName]/?type=cognac' },
      {
        name: 'Limoncello',
        as: '/spirits/?type=limoncello',
        href: '/[categoryName]/?type=limoncello',
      },
      { name: 'Sambuca', as: '/spirits/?type=sambuca', href: '/[categoryName]/?type=sambuca' },
      { name: 'Sake', as: '/spirits/?type=sake', href: '/[categoryName]/?type=sake' },
      { name: 'Amaretto', as: '/spirits/?type=amaretto', href: '/[categoryName]/?type=amaretto' },
      { name: 'Vermouth', as: '/spirits/?type=vermouth', href: '/[categoryName]/?type=vermouth' },
      { name: 'Pineau', as: '/spirits/?type=pineau', href: '/[categoryName]/?type=pineau' },
      { name: 'Hierbas', as: '/spirits/?type=hierbas', href: '/[categoryName]/?type=hierbas' },
      { name: 'Glühwein', as: '/spirits/?type=glühwein', href: '/[categoryName]/?type=glühwein' },
      { name: 'Bitters', as: '/spirits/?type=bitters', href: '/[categoryName]/?type=Bitters' },
      { name: 'Rum', as: '/spirits/?type=Rum', href: '/[categoryName]/?type=Rum' },
      { name: 'Pastis', as: '/spirits/?type=Pastis', href: '/[categoryName]/?type=Pastis' },
      // { name: 'Mezcal', as: '/spirits/?type=mezcal', href: '/[categoryName]/?type=mezcal' },
      // { name: 'Grappa', as: '/spirits/?type=grappa', href: '/[categoryName]/?type=grappa' },
      // { name: 'Premix', as: '/spirits/?type=premix', href: '/[categoryName]/?type=premix' },
    ],
    facets: ['type'],
    description:
      'Wat is er gezelliger om samen in gezelschap te genieten van een smakelijke likeur of shot. Bij Shopdeal kan u een uitgebreid aanbod terugvinden van spirits zoals Tequila, Cointreau, Baileys, sake en jenevers. Een goede Congnac of Porto mag zeker niet ontbreken in dit aanbod van geweldige aanbiedingen. Kom snel kijken naar dit uitgebreide aanbod en geweldige prijzen!',
  },
  {
    name: 'non-alcohol',
    inNav: true,
    displayName: 'alcoholvrij',
    href: '/[categoryName]',
    as: '/non-alcohol/',
    subCategories: null,
    facets: ['type'],
    description:
      'Bij Shopdeal kan u een groot aanbod van alcoholvrije dranken terugvinden. Deze gaan van frisdranken tot tonics en de meer gespecialiseerde dranken voor de liefhebbers. Geen liefhebbers van alcohol op bezoek? Surf zeker eens naar Shopdeal want de prijzen zijn echte deals.',
  },
  {
    name: 'koffie',
    inNav: true,
    displayName: 'koffie',
    href: '/[categoryName]',
    as: '/koffie/',
    subCategories: null,
    facets: ['type', 'country'],
    description:
      'Koffiemachine deals kan je vinden bij shopdeal. Bij een goede koffie hoort een goede koffieboon maar ook de machine om deze koffie te maken mag niet vergeten worden. Zin in een geweldige deal, schaf jezelf een koffiemachine aan. Bezoek snel onze website om te genieten van deze straffe koffie deals.',
  },
  {
    name: 'relatiepakketten',
    inNav: true,
    displayName: 'relatiepakketten',
    href: '/relatiepakketten',
    as: '/relatiepakketten/',
    subCategories: null,
    facets: ['category'],
    description:
      'Bij Shopdeal kan je aan straffe prijzen geweldige relatiepakketten kopen. Elk pakket is een verrassing opzich en uw klant of relatie zal zeker onder de indruk zijn van uw keuze die uw kwaliteitsvolle relatie bevestigt.',
  },
  {
    name: 'deals',
    inNav: true,
    displayName: 'deals',
    href: '/[categoryName]',
    as: '/deals/',
    subCategories: null,
    facets: [],
    description:
      'Bekijk onze beste deals van het moment. Bezoek regelmatig onze website voor de allerlaatste deals.',
  },
  {
    name: 'search',
    inNav: false,
    displayName: 'zoekresultaten',
    href: '/[categoryName]',
    as: '/search/',
    subCategories: null,
    facets: [],
    description: 'Zoek en vind hier de beste deals van het moment.',
  },
];

export default categories;
