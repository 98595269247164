import React from 'react';

function Logo({
  squared,
  className,
  width,
  height,
  light = false,
}: {
  squared?: boolean;
  className?: string;
  width?: any;
  height?: any;
  light?: boolean;
}) {
  if (squared) {
    return (
      <svg
        width={width || '205px'}
        height={height || '106px'}
        className={className || ''}
        viewBox="0 0 205 106"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="shopdeal_logo_vertical" fillRule="nonzero">
            <path
              d="M25.17,50.48 C14.83,50.48 5.49,46.78 0,41.78 L8.27,31.3 C13.48,35.37 20.32,38.08 24.67,38.08 C27.81,38.08 29.31,37.43 29.31,35.79 C29.31,30.87 3.14,31.3 3.14,15.62 C3.14,7.13 10.27,0 24.46,0 C35.87,0 43.21,4.06 47.85,8.41 L39.43,17.68 C34.43,14.47 28.81,12.41 25.03,12.41 C22.67,12.41 21.11,13.12 21.11,14.69 C21.11,19.61 47.85,18.69 47.85,34.3 C47.85,43.42 40.29,50.48 25.17,50.48 Z"
              id="Path"
              fill={light ? '#fff' : '#231F20'}
            />
            <polygon
              id="Path"
              fill={light ? '#fff' : '#231F20'}
              points="80.29 49 80.29 32.3 70.81 32.3 70.81 49 51.2 49 51.2 1.5 70.81 1.5 70.81 19.75 80.29 19.75 80.29 1.5 99.9 1.5 99.9 49"
            />
            <path
              d="M128.49,50.48 C113.09,50.48 103.82,41.57 103.82,29.73 L103.82,20.73 C103.82,8.84 113.09,0 128.49,0 C143.89,0 153.09,8.84 153.09,20.68 L153.09,29.73 C153.09,41.57 143.82,50.48 128.49,50.48 Z M133.13,19 C133.13,15.58 131.7,13.72 128.49,13.72 C125.28,13.72 123.79,15.58 123.79,19 L123.79,31.4 C123.79,34.76 125.29,36.61 128.49,36.61 C131.69,36.61 133.13,34.76 133.13,31.4 L133.13,19 Z"
              id="Shape"
              fill="#ED1C24"
            />
            <path
              d="M185.83,33.73 L176.63,33.73 L176.63,49 L157,49 L157,1.5 L185.52,1.5 C196.36,1.5 202.52,7.5 202.52,15.69 L202.52,19.18 C202.58,27.45 196.66,33.73 185.83,33.73 Z M183.26,17 C183.26,14.86 182.26,13.86 179.76,13.86 L176.63,13.86 L176.63,23.78 L179.76,23.78 C182.26,23.78 183.26,22.71 183.26,20.64 L183.26,17 Z"
              id="Shape"
              fill={light ? '#fff' : '#231F20'}
            />
            <path
              d="M24.07,106 L0.54,106 L0.54,58.5 L24.07,58.5 C40.47,58.5 48.67,66.84 48.67,78.32 L48.67,86.73 C48.67,98.21 40.47,106 24.07,106 Z M28.91,78.33 C28.91,74.62 27.42,71.84 22.91,71.84 L20.07,71.84 L20.07,92.58 L22.93,92.58 C27.42,92.58 28.93,89.8 28.93,86.09 L28.91,78.33 Z"
              id="Shape"
              fill={light ? '#fff' : '#231F20'}
            />
            <polygon
              id="Path"
              fill={light ? '#fff' : '#231F20'}
              points="52.52 106 52.52 58.5 94.09 58.5 94.09 71.62 72.09 71.62 72.09 77.46 86 77.46 86 86.09 72.13 86.09 72.13 91.94 94.13 91.94 94.13 105.94"
            />
            <path
              d="M126,106 L124.15,98.51 L113.7,98.51 L111.56,106 L94.23,106 L109.56,58.5 L130.56,58.5 L145.93,106 L126,106 Z M119.44,77.19 L116.59,88.74 L121.8,88.74 L119.44,77.19 Z"
              id="Shape"
              fill={light ? '#fff' : '#231F20'}
            />
            <polygon
              id="Path"
              fill={light ? '#fff' : '#231F20'}
              points="147.36 106 147.36 58.5 166.89 58.5 166.89 92 188.71 92 188.71 106"
            />
            <polygon id="Path" fill="#ED1C24" points="191.71 106 191.71 93 204.54 93 204.54 106" />
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg
      width={width || '410px'}
      height={height || '51px'}
      className={className || ''}
      viewBox="0 0 410 51"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="shopddeal_logo_horizontal" fillRule="nonzero">
          <path
            d="M25.17,50.48 C14.83,50.48 5.49,46.78 0,41.78 L8.27,31.3 C13.48,35.37 20.32,38.08 24.67,38.08 C27.81,38.08 29.31,37.43 29.31,35.79 C29.31,30.87 3.14,31.3 3.14,15.62 C3.14,7.13 10.27,0 24.46,0 C35.87,0 43.21,4.06 47.85,8.41 L39.43,17.68 C34.43,14.47 28.81,12.41 25.03,12.41 C22.67,12.41 21.11,13.12 21.11,14.69 C21.11,19.61 47.85,18.69 47.85,34.3 C47.85,43.42 40.29,50.48 25.17,50.48 Z"
            id="Path"
            fill={light ? '#fff' : '#231F20'}
          />
          <polygon
            id="Path"
            fill={light ? '#fff' : '#231F20'}
            points="80.29 49 80.29 32.3 70.81 32.3 70.81 49 51.2 49 51.2 1.5 70.81 1.5 70.81 19.75 80.29 19.75 80.29 1.5 99.9 1.5 99.9 49"
          />
          <path
            d="M128.49,50.48 C113.09,50.48 103.82,41.57 103.82,29.73 L103.82,20.73 C103.82,8.84 113.09,0 128.49,0 C143.89,0 153.09,8.84 153.09,20.68 L153.09,29.73 C153.09,41.57 143.82,50.48 128.49,50.48 Z M133.13,19 C133.13,15.58 131.7,13.72 128.49,13.72 C125.28,13.72 123.79,15.58 123.79,19 L123.79,31.4 C123.79,34.76 125.29,36.61 128.49,36.61 C131.69,36.61 133.13,34.76 133.13,31.4 L133.13,19 Z"
            id="Shape"
            fill="#ED1C24"
          />
          <path
            d="M185.83,33.73 L176.63,33.73 L176.63,49 L157,49 L157,1.5 L185.52,1.5 C196.36,1.5 202.52,7.5 202.52,15.69 L202.52,19.18 C202.58,27.45 196.66,33.73 185.83,33.73 Z M183.26,17 C183.26,14.86 182.26,13.86 179.76,13.86 L176.63,13.86 L176.63,23.78 L179.76,23.78 C182.26,23.78 183.26,22.71 183.26,20.64 L183.26,17 Z"
            id="Shape"
            fill={light ? '#fff' : '#231F20'}
          />
          <path
            d="M229.54,49 L206,49 L206,1.5 L229.53,1.5 C245.93,1.5 254.13,9.84 254.13,21.32 L254.13,29.73 C254.14,41.21 245.94,49 229.54,49 Z M234.39,21.33 C234.39,17.62 232.89,14.84 228.39,14.84 L225.53,14.84 L225.53,35.58 L228.39,35.58 C232.88,35.58 234.39,32.8 234.39,29.09 L234.39,21.33 Z"
            id="Shape"
            fill={light ? '#fff' : '#231F20'}
          />
          <polygon
            id="Path"
            fill={light ? '#fff' : '#231F20'}
            points="258 49 258 1.5 299.57 1.5 299.57 14.62 277.57 14.62 277.57 20.46 291.4 20.46 291.4 29.09 277.6 29.09 277.6 34.94 299.6 34.94 299.6 49"
          />
          <path
            d="M331.5,49 L329.65,41.51 L319.17,41.51 L317,49 L299.7,49 L315,1.5 L336,1.5 L351.4,49 L331.5,49 Z M324.94,20.19 L322.09,31.74 L327.3,31.74 L324.94,20.19 Z"
            id="Shape"
            fill={light ? '#fff' : '#231F20'}
          />
          <polygon
            id="Path"
            fill={light ? '#fff' : '#231F20'}
            points="352.83 49 352.83 1.5 372.36 1.5 372.36 35 394.18 35 394.18 49"
          />
          <polygon id="Path" fill="#ED1C24" points="397.18 49 397.18 35.94 410 35.94 410 49" />
        </g>
      </g>
    </svg>
  );
}

export default Logo;
