/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import Link from 'next/link';

import { useRouter } from 'next/router';
import categories from '../../../utils/categories';

const NavLinks = ({ onClick, sideDrawer }: { onClick?: () => void; sideDrawer?: boolean }) => {
  const { asPath } = useRouter();

  return (
    <ul
      className="navLinks p-0 m-0"
      onClick={() => {
        if (onclick) onClick();
      }}
    >
      {categories
        .filter(({ inNav }) => !!inNav)
        .map(
          ({
            name,
            displayName,
            // subCategories,
            href,
            as,
          }) => (
            <li
              key={`navlink-${name}`}
              // className={`${subCategories ? 'dropdown' : ''}`}
              id={`${name === 'deals' && !sideDrawer ? 'dealNav' : ''}`}
            >
              <Link
                href={href}
                as={as}
                className={`px-4 py-3 px-lg-2 nav-link${
                  as === asPath.split('?')[0] ? ' active' : ' inactive'
                }`}
              >
                {displayName} {displayName === 'deals' && <span>.</span>}
              </Link>
              {/* {subCategories && (
            <ul className="subCategories">
              {subCategories.map(({ href: subHref, as: subAs, name: subName }) => (
                <li>
                  <Link href={subHref} as={subAs}>
                    <a>{subName}</a>
                  </Link>
                </li>
              ))}
            </ul>
          )} */}
            </li>
          )
        )}
    </ul>
  );
};

export default NavLinks;
