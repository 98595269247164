/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Badge,
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';
import { IoIosCart, IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { useCart } from 'shared/context/CartContext';
import { useUser } from 'shared/context/UserContext';
import Search from 'shared/components/Search';
import SideDrawer from './SideDrawer';

import NavLinks from './NavLinks';
import Logo from '../../Logo';

function Navbar() {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const { user, dashboardAccess, signout } = useUser();
  const { quantity } = useCart();
  const [cartAnimating, setCartAnimating] = useState(false);

  const usps = [
    // 'Gratis verzending vanaf € 250,00',
    'Altijd de beste deals',
  ];
  const toggleSideDrawer = () => setSideDrawerOpen(!sideDrawerOpen);

  useEffect(() => {
    if (quantity > 0) setCartAnimating(true);
    setTimeout(() => {
      setCartAnimating(false);
    }, 300);
  }, [quantity]);

  return (
    <>
      {sideDrawerOpen && <div id="backdrop" onClick={toggleSideDrawer} />}
      <SideDrawer open={sideDrawerOpen} handleClose={toggleSideDrawer} />
      <div style={{ backgroundColor: '#f2f2f2' }}>
        <Container>
          <ul className="mb-0 ps-0 d-none d-md-block">
            {usps.map((usp, i) => (
              <li
                key={`usp_${i}`}
                style={{ fontSize: 12 }}
                className={`text-muted  my-1 d-inline-block ${i > 0 ? 'ms-2' : ''}`}
              >
                <IoMdCheckmarkCircleOutline className="me-1" color="#38A169" />
                <span className="position-relative" style={{ lineHeight: '0.8px', top: 1 }}>
                  {usp}
                </span>
              </li>
            ))}
          </ul>
        </Container>
      </div>
      <div id="nav-wrapper" className="bg-white sticky-top shadow-sm">
        <Container className="py-2">
          <Row>
            <Col
              xs={{ size: 3 }}
              lg={{ size: 4, order: 3 }}
              className="d-flex justify-content-lg-end align-items-center"
            >
              <div className="d-none d-lg-block">
                {user && user.firstName ? (
                  <div className="d-flex align-items-center">
                    {dashboardAccess && (
                      <UncontrolledDropdown inNavbar>
                        <DropdownToggle caret color="white">
                          Dashboard
                        </DropdownToggle>
                        <DropdownMenu right className="p-3" style={{ minWidth: '16rem' }}>
                          <Link
                            href="/dashboard/producten"
                            className="p-2 w-100 d-block text-center text-dark"
                          >
                            Producten
                          </Link>
                          <Link
                            href="/dashboard/merken"
                            className="p-2 w-100 d-block text-center text-dark"
                          >
                            Merken
                          </Link>
                          <Link
                            href="/dashboard/orders"
                            className="p-2 w-100 d-block text-center text-dark"
                          >
                            Orders
                          </Link>
                          <Link
                            href="/dashboard/banners"
                            className="p-2 w-100 d-block text-center text-dark"
                          >
                            Banners
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                    <UncontrolledDropdown inNavbar>
                      <DropdownToggle caret color="white">
                        {user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)}
                      </DropdownToggle>
                      <DropdownMenu right className="p-3" style={{ minWidth: '16rem' }}>
                        <Link
                          href="/my-shopdeal/orders"
                          className="p-2 w-100 d-block text-center text-dark"
                        >
                          Orders
                        </Link>
                        <Link
                          href="/my-shopdeal/account"
                          className="p-2 w-100 d-block text-center text-dark"
                        >
                          Account
                        </Link>
                        <Button color="white" onClick={signout} className="w-100">
                          Uitloggen
                        </Button>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                ) : (
                  <Link href="/login" className="px-4">
                    Inloggen
                  </Link>
                )}
              </div>
              <Link href="/cart" id="navCart">
                <IoIosCart color="#343a40" style={{ width: 18, height: 18 }} />
                <Badge
                  pill
                  className={`bg-success position-absolute ${cartAnimating ? 'animate' : ''}`}
                >
                  {quantity}
                </Badge>
              </Link>
            </Col>
            <Col
              xs={{ size: 6 }}
              lg={{ size: 4, order: 2 }}
              className="d-flex justify-content-center align-items-center"
            >
              <Link href="/" aria-label="home" id="logoLink">
                <Logo height="20px" width="160" className="my-1" />
              </Link>
            </Col>
            <Col
              xs={{ size: 3 }}
              lg={{ size: 12 }}
              className="d-flex align-items-center justify-content-end d-lg-none"
            >
              <button
                aria-label="Menu"
                id="hamburgerButton"
                onClick={toggleSideDrawer}
                type="button"
              >
                <div className="hamburger">
                  <span className="line" />
                  <span className="line" />
                  <span className="line" />
                </div>
              </button>
            </Col>
            <Col xs="12" lg={{ size: 4, order: 1 }} className="d-flex mt-3 mt-md-0">
              <Search placeholder="Vind jouw deal" className="w-100" />
            </Col>
          </Row>
        </Container>

        <div className="d-none d-lg-block" id="productNavLinks">
          <Container id="headerNav" tag="nav">
            <NavLinks />
          </Container>
        </div>
      </div>
    </>
  );
}

export default Navbar;
