import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Link from 'next/link';
import { FaFacebook } from 'react-icons/fa';
import Bancontact from 'shared/components/payment/Bancontact';
import Belfius from 'shared/components/payment/Belfius';
import Ing from 'shared/components/payment/Ing';
import Kbc from 'shared/components/payment/Kbc';
import Ideal from 'shared/components/payment/Ideal';
import Bpost from 'shared/components/shipping/Bpost';
import Dhl from 'shared/components/shipping/Dhl';
import Dpd from 'shared/components/shipping/Dpd';
import PayPal from 'shared/components/payment/Paypal';
import Instagram from 'shared/components/svg/Instagram';
import Logo from '../Logo';
import { companies } from '../../config';

function Footer() {
  // const brands = null;
  const year = new Date().getFullYear();

  const {
    title,
    location: { street, number, postalCode, city },
    openingHours,
    contact: {
      // phone,
      email,
    },
    social: { facebook, instagram },
  } = companies.shopdeal;

  return (
    <footer id="footer">
      <div className="bg-lightGray">
        <Container>
          <Row>
            <Col
              xs="12"
              md={{ size: 8, offset: 2 }}
              id="footerBrands"
              className="d-flex flex-wrap justify-content-around my-4"
            >
              <PayPal />
              <Bancontact />
              <Belfius />
              <Ideal />
              <Ing />
              <Kbc />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-4" id="footer-top">
        <Container>
          <Row>
            <Col xs={{ size: 12, order: 3 }} md={{ size: 5, order: 1 }} className="py-3">
              <h2>{title}</h2>
              <address className="text-white">
                {street} {number}, {postalCode} {city}
              </address>
              <div className="mb-3">
                <a className="d-block text-white" href={`mailto:${email}`}>
                  {email}
                </a>
              </div>
              <p className="m-0">Bereikbaarheid: {openingHours}</p>
              <div className="socials mt-3">
                <a
                  href={facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-2"
                  aria-label="Facebook"
                >
                  <FaFacebook size="25px" color="#3b5998" />
                </a>
                <a
                  href={instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-2"
                  id="instagram"
                  aria-label="Instagram"
                >
                  <Instagram />
                </a>
              </div>
            </Col>
            <Col
              xs={{ size: 12, order: 1 }}
              md={{ size: 2, order: 1 }}
              className="py-3 d-flex justify-content-center align-items-start"
            >
              <Logo squared className="mx-auto d-block" height="40" light />
            </Col>
            <Col
              xs={{ size: 12, order: 2 }}
              md={{ size: 3, offset: 2, order: 1 }}
              className="d-flex flex-column justify-content-between py-3"
            >
              <div className="mb-3">
                <Link href="/algemene-voorwaarden" className="mb-2 d-block text-light">
                  Algemene voorwaarden
                </Link>
                <Link href="/faq" className="mb-2 d-block text-light">
                  Veelgestelde vragen
                </Link>
                <Link href="/cookie-policy" className="mb-2 d-block text-light">
                  Cookie policy
                </Link>
                <Link href="/privacy-policy" className="mb-2 d-block text-light">
                  Privacy policy
                </Link>
              </div>
              <div className="d-flex justify-content-between">
                <Bpost />
                <Dhl />
                <Dpd />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="py-2">
        <Row>
          <Col
            md={{ size: 4, offset: 4 }}
            className="d-flex justify-content-around align-items-center bg-muted"
          >
            <span className="text-muted" style={{ fontSize: 12 }}>
              © {year} Casymo B.V.
            </span>
            <span className="text-muted" style={{ fontSize: 12 }}>
              0536.358.530
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
