export const endpoint =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8888' : 'https://api.shopdeal.be';

export const companies = {
  shopdeal: {
    title: 'ShopDeal',
    location: {
      street: 'Pareinpark',
      number: '29 b3',
      postalCode: '9120',
      city: 'Beveren',
      countryCode: 'BE',
    },
    contact: {
      phone: null,
      email: 'info@shopdeal.be',
    },
    openingHours: 'maandag t/m vrijdag van 9.00 uur tot 14.00 uur',
    social: {
      facebook: 'https://www.facebook.com/Shopdeal.be',
      instagram: 'https://www.instagram.com/shopdeal.be/',
    },
    banc: {
      account: 'BE66 3631 2193 9043',
      BIC: 'BBRUBEBB',
      name: 'ING',
    },
  },
};
