/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import dayjs from 'dayjs';
import ContextProviders from 'shared/context';

import { pageview } from 'shared/utils/gtm';
import Layout from '../components/layout';

import 'typeface-proza-libre';
import '../scss/main.scss';
import 'react-image-lightbox/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'dayjs/locale/nl-be';

Router.events.on('routeChangeStart', (url) => {
  console.log(`Loading: ${url}`);

  NProgress.start();
});
Router.events.on('routeChangeComplete', (url) => {
  NProgress.done();
  pageview(url);
});
Router.events.on('routeChangeError', () => NProgress.done());

dayjs.locale('nl-be');

function MyApp({ Component, pageProps }) {
  return (
    <ContextProviders>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </ContextProviders>
  );
}

export default MyApp;
