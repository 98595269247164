/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Link from 'next/link';
import { Button, Collapse } from 'reactstrap';
import { MdClose } from 'react-icons/md';
import { useUser } from 'shared/context/UserContext';
import NavLinks from './NavLinks';

function SideDrawer({ handleClose, open }) {
  const { user, signout } = useUser();
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  const toggleActiveMenu = ({ target: { name } }: any) =>
    setActiveMenu((prev) => (prev === name ? '' : name));

  return (
    <nav
      id="sideDrawer"
      className={`bg-black position-relatve d-lg-none ${open ? 'open' : 'closed'}`}
    >
      <div className="d-flex justify-content-end mb-3">
        <Button onClick={handleClose} color="black">
          <MdClose size="35" color="#EE1C25" />
        </Button>
      </div>
      <Button
        className="rounded-0 nav-link d-flex w-100 justify-content-between px-4 py-3"
        color="primary"
        name="product"
        onClick={toggleActiveMenu}
      >
        Producten
        <svg
          className={`d-inline-block toggleArrow${
            activeMenu === 'product' ? ' active' : ' inactive'
          }`}
          stroke="#fff"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="20px"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </Button>
      <Collapse isOpen={activeMenu === 'product'}>
        <NavLinks onClick={handleClose} sideDrawer />
      </Collapse>

      {user && !user.guest && user.permissions ? (
        <>
          {user.permissions.indexOf('ADMIN') !== -1 && (
            <>
              <Button
                color="black"
                className="rounded-0 nav-link d-flex w-100 justify-content-between px-4 py-3"
                onClick={toggleActiveMenu}
                name="dashboard"
              >
                Dashboard
                <svg
                  className={`d-inline-block toggleArrow${
                    activeMenu === 'dashboard' ? ' active' : ' inactive'
                  }`}
                  stroke="#fff"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="20px"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </Button>
              <Collapse isOpen={activeMenu === 'dashboard'}>
                <ul className="navLinks p-0 m-0" onClick={handleClose}>
                  {/* <li>
                    <Link href="/dashboard/producten">
                      <a className="px-5 py-3 nav-link">Producten</a>
                    </Link>
                  </li> */}
                  <li>
                    <Link href="/dashboard/merken" className="px-5 py-3 nav-link">
                      Merken
                    </Link>
                  </li>
                  <li>
                    <Link href="/dashboard/orders" className="px-5 py-3 nav-link">
                      Orders
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </>
          )}
          <Button
            color="black"
            className="rounded-0 nav-link d-flex w-100 justify-content-between px-4 py-3"
            name="profile"
            onClick={toggleActiveMenu}
          >
            {user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)}
            <svg
              className={`d-inline-block toggleArrow${
                activeMenu === 'profile' ? ' active' : ' inactive'
              }`}
              stroke="#fff"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="20px"
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </Button>
          <Collapse isOpen={activeMenu === 'profile'}>
            <ul className="navLinks p-0 m-0" onClick={handleClose}>
              <li>
                <Button
                  color="black"
                  className="rounded-0 w-100 px-5 py-3 nav-link text-start"
                  onClick={signout}
                >
                  Uitloggen
                </Button>
              </li>
              <li>
                <Link href="/my-shopdeal/orders" className="rounded-0 px-5 py-3 nav-link">
                  Orders
                </Link>
              </li>
              <li>
                <Link href="/my-shopdeal/account" className="rounded-0 px-5 py-3 nav-link">
                  Account
                </Link>
              </li>
            </ul>
          </Collapse>
        </>
      ) : (
        <Link
          href="/login"
          onClick={handleClose}
          className="nav-link text-white w-100 d-flex justify-content-between px-4 py-3"
        >
          Inloggen
        </Link>
      )}
      <Link
        href="/faq"
        onClick={handleClose}
        className="nav-link text-white w-100 d-flex justify-content-between px-4 py-3"
      >
        Veelgestelde vragen
      </Link>
      <Link
        href="/algemene-voorwaarden"
        onClick={handleClose}
        className="nav-link text-white w-100 d-flex justify-content-between px-4 py-3"
      >
        Algemene voorwaarden
      </Link>
    </nav>
  );
}

export default SideDrawer;
